<template>
  <b-card>
    <download-em-lote
      tipo-arquivo="todos os Sublimites"
      tipo="Sublimite"
      :filtro="filtroAtual.filtroBy"
      @baixar-lote="baixarEmLote"
    />
    <sublimite-list
      ref="sublimiteList"
      :botoes-opcoes="botoesOption"
      :visualizacao-opcoes="visualizacao"
      :filtros="filtros"
      :tem-filtro-rapido="false"
    >
      <template
        slot="custom"
        slot-scope="{ props} "
      >
        <b-badge
          v-if="props.column.field === 'rbaSublimite' || props.column.field === 'rbaLimite'"
          :variant="getColorTag(props.row[props.column.field])"
        >
          {{ props.row[props.column.field].toString().replace('.', ',') }} %
        </b-badge>
        <span
          v-if="props.column.field === 'razaoSocial'"
          @click.stop="$refs[`acoes${props.row.id}`].abrirArquivo(props.row)"
        >
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template
        slot="acoes"
        slot-scope="{ props} "
      >
        <span
          v-b-tooltip.hover
          :title="getMensagemTooltip(props.row)"
        >
          <b-btn
            variant="none"
            class="text-primary btn-none"
            style="padding: 0rem;font-size: 17px; margin-top: -6px"
            :disabled="props.row.mensagem == undefined"
            @click="abrirMensagens(props.row)"
          >
            <font-awesome-icon
              v-if="props.row.mensagem && props.row.mensagem.lido"
              icon="fa-solid fa-envelope-open-text"
            />
            <font-awesome-icon
              v-else-if="props.row.mensagem && !props.row.mensagem.lido"
              icon="fa-solid fa-envelope"
            />
            <font-awesome-icon
              v-else
              icon="fa-solid fa-envelope"
            />
          </b-btn>
        </span>
        <acoes
          :ref="`acoes${props.row.id}`"
          :grid-props="props"
        />
      </template>
    </sublimite-list>
    <modal-caixa-postal
      :linha="linha"
      :ocultar-email="true"
    />
    <loading
      :show-cancel-button="false"
      :is-loading="isLoading"
      :descricao="'Buscando'"
    />
  </b-card>
</template>

<style lang="scss">
.modal-lg {
  max-width: 1000px!important;
}

.data-width{
  width: 120px!important;
}

.tamanho-cnpj {
  min-width: 194px!important;
}
</style>

<script>

import apiCaixaPostal from '@/app/caixa-postal/shared/services/api'
import colunas from './shared/components/colunas'
import api from './shared/services/api'

export default {
  name: 'SublimiteView',
  components: {
    SublimiteList: () => import('@pilar/components/visualizacao-base/VisualizacaoBase.vue'),
    Acoes: () => import('./shared/components/acoes/Acoes.vue'),
    DownloadEmLote: () => import('@app/download-lote/DownloadEmLote.vue'),
    ModalCaixaPostal: () => import('@/app/shared/components/modal-caixa-postal/ModalCaixaPostal.vue'),
    Loading: () => import('@pilar/components/loading/Loading.vue'),
    // FiltroRapido: () => import('@pilar/components/visualizacao-base/components/filtro-rapido/FiltroRapido.vue'),
  },
  data() {
    return {
      botoesOption: null,
      linha: null,
      visualizacao: {
        acaoClass: 'col-td-acao-3',
        disabledCheckbox: true,
        placeholderBusca: 'Buscar por Razão Social, CNPJ/CPF, Competência',
      },
      filtros: [{ colunas: ['CadastroFederalSemMascara', 'CadastroFederal', 'RazaoSocial', 'Competencia'], valor: null }],
      // filtrosRapidos: [
      //   {
      //     id: 'Regular',
      //     colunas: ['Regular'],
      //     label: 'Regulares',
      //     valor: '1,2',
      //   },
      //   {
      //     id: 'Irregular',
      //     colunas: ['Irregular'],
      //     label: 'Irregulares',
      //     valor: '3',
      //   },
      //   {
      //     id: 'Motivo',
      //     colunas: ['Motivo'],
      //     label: 'Motivo não geração',
      //     valor: '4',
      //   }],
      criando: true,
      aplicando: false,
      filtroAtual: {},
      isLoading: false,
    }
  },
  created() {
    this.visualizacao.colunas = colunas
    this.visualizacao.evento = this.carregar
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    carregar(pagina, tamanhoPagina, filtro) {
      this.filtroAtual = filtro

      return api.getPaginado(pagina, tamanhoPagina, filtro)
    },
    getColorTag(value) {
      if (value < 70) {
        return 'light-success'
      }
      if (value < 100) {
        return 'light-warning'
      }
      return 'light-danger'
    },
    baixarEmLote(evt) {
      api.baixaEmLote(this.filtroAtual, evt)
    },
    getMensagemTooltip(row) {
      if (!row || row.mensagem === undefined) {
        return 'Sem termo'
      }
      return row.mensagem.lido ? 'Visualizar termo' : 'Abrir termo'
    },
    abrirMensagens(row) {
      this.linha = row.mensagem

      if (row.mensagem.mensagem) {
        this.$bvModal.show('m-Mensagens')
      } else {
        this.$confirmar('Mensagem não lida no portal do e-CAC',
          'Você gostaria de ler a mensagem agora? Ao confirmar a leitura será consumido um crédito do seu saldo em conta.')
          .then(confirmado => {
            if (confirmado && confirmado.isConfirmed) {
              this.isLoading = true
              apiCaixaPostal.getMensagemNaoLida(row.mensagem.id)
                .then(payload => {
                  this.$set(row.mensagem, 'lido', true)
                  this.$set(row.mensagem, 'mensagem', payload.data)
                  this.isLoading = false
                  this.$bvModal.show('m-Mensagens')
                }).catch(err => {
                  this.isLoading = false
                  this.$erro(`Erro ao abrir a mensagem: ${err}`)
                })
            }
          })
      }
    },
  },
}
</script>
